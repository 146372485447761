import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, FormControlLabel, TextField, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Admin_ItemsList from './Admin_ItemsList';

const useStyles = makeStyles((theme) => ({
  checkboxGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
  },
  searchTextbox: {
    width: 300,
  },
}));

const Admin = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [showZeroNoPrice, setShowZeroNoPrice] = useState(false);
  const [showHiddenOnly, setShowHiddenOnly] = useState(false);
  //const [showNonHiddenOnly, setShowNonHiddenOnly] = useState(false);
  const [showNoImageOnly, setShowNoImageOnly] = useState(false);

  const [items, setItems] = useState([]);

  const fetchItems = () => {
    //alert('4')
    fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  };

  
  useEffect(() => {
    // fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setItems(data);
    //   });
    fetchItems();
  }, []);

  // useEffect(() => {
  //   fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setItems(data);
  //     });
  // }, []);

  const filteredItems = items.filter((item) => {
    const matchesSearchQuery = item.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesZeroNoPrice = !showZeroNoPrice || item.price === 0 || item.price === null || item.price === undefined;
    const matchesHiddenOnly = !showHiddenOnly || item.blocked;
    //const matchesNonHiddenOnly = !showNonHiddenOnly || !item.hidden;
    const matchesNoImageOnly = !showNoImageOnly || !item.imagePath;

    return matchesSearchQuery && matchesZeroNoPrice && matchesHiddenOnly && matchesNoImageOnly;
    //matchesNonHiddenOnly && ;
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleZeroNoPriceChange = (event) => {
    setShowZeroNoPrice(event.target.checked);
  };

  const handleHiddenOnlyChange = (event) => {
    setShowHiddenOnly(event.target.checked);
  };

  // const handleNonHiddenOnlyChange = (event) => {
  //   setShowNonHiddenOnly(event.target.checked);
  // };

  const handleNoImageOnlyChange = (event) => {
    setShowNoImageOnly(event.target.checked);
  };

  const handleExportShipmentsClick = () => {
    console.log('Exporting last month shipments');
    fetch('https://posapi.duvelusa.com/Orders/GetExportLastMonthShipments')
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const timestamp = new Date().toLocaleDateString().replace(/\//g, '');
        a.download = `last_month_shipments_${timestamp}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      })
      .catch(error => {
        console.error('Download failed:', error);
      });
  };

  const handleExportPriceHistoryClick = () => {
    console.log('Exporting price history');
    fetch('https://posapi.duvelusa.com/Orders/GetExportPriceHistory')
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const timestamp = new Date().toLocaleDateString().replace(/\//g, '');
        a.download = `price_history_${timestamp}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      })
      .catch(error => {
        console.error('Download failed:', error);
      });
  };

  return (
    <div style={{margin: 'auto', width: '80%'}}>
      <Paper>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  className={classes.searchTextbox}
                  label="Search by description"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  variant="outlined"
                  width="100%"
                />
              </Grid>
              <Grid item className={classes.checkboxGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showZeroNoPrice}
                      onChange={handleZeroNoPriceChange}
                      color="primary"
                    />
                  }
                  label="No Price"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showHiddenOnly}
                      onChange={handleHiddenOnlyChange}
                      color="primary"
                    />
                  }
                  label="Hidden"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNonHiddenOnly}
                      onChange={handleNonHiddenOnlyChange}
                      color="primary"
                    />
                  }
                  label="Non-Hidden"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNoImageOnly}
                      onChange={handleNoImageOnlyChange}
                      color="primary"
                    />
                  }
                  label="No Image"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleExportShipmentsClick} style={{marginRight: '8px'}}>
              Export Last Month Shipments
            </Button>
            <Button variant="contained" color="primary" onClick={handleExportPriceHistoryClick} style={{marginRight: '8px'}}>
              Export Price History
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div>Displaying {filteredItems.length} of {items.length}</div>
      <Admin_ItemsList originalItems={filteredItems} refreshItems={fetchItems} />
    </div>
  );
};

export default Admin;