import { useEffect, useState, createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BrandNavBar from './POS_Components/BrandNavBar';
import ItemsList from './POS_Components/ItemsList';
import FilterPanel from './POS_Components/FilterPanel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Item from './POS_Components/Item__hold';
import ShoppingCart from './POS_Components/ShoppingCart';
import Backdrop from '@material-ui/core/Backdrop';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormControl, InputLabel, Select, MenuItem, IconButton, Popover, Button } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { OriginalItemsProvider } from './POS_OriginalItemsProvider';
import items from '../POS/DummyData/items.json';
import pos_portal_banner from '../../imgs/DuvelUSA_POS-Portal-Banner_comp.jpg'
import { Paper, Typography } from '@material-ui/core';
import Admin from './POS_Components/Admin';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    //height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  customComponent: {
    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    //margin: theme.spacing(1),
    //backgroundColor: 'lightgray'backgroundColor: 'white',
    flex: 1,
  },
  linkHover: {  
    '&:hover': {
      color: 'purple',
      fontWeight: '600'
    },
  },
  startNewOrderButton: {
    '&:hover': {
      color: 'purple',
      fontWeight: '900'
    },
  },
  overlay: {
    position: 'fixed',
    //top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    //backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: 'blue',
    pointerEvents: 'none',
    zIndex: 9990,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1000, // Optional: this ensures the sticky element stays on top of other elements
  },
}));
function POS_MainPage(props) {
  
  const classes = useStyles();
  
  const [filteredBrands, setfilteredBrands] = useState([]);
  const [filteredItemTypes, setfilteredItemTypes] = useState([]); //categories
  const [startNewOrder, setStartNewOrder] = useState(false);
  const [shoppingCart, setShoppingCart] = useState({});
  const [originalItems, setOriginalItems] = useState(Object.values(items).flat());
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [ShowAdmin, setShowAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleGearClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Call the logout function passed as a prop
    props.onLogOut();
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'logout-popover' : undefined;


  useEffect(() => {
    fetch('https://posapi.duvelusa.com/Orders/' + props.username + '/GetCustomers_ByPOSContactUsername', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => setCustomers(data))
    .catch((error) => {
      console.error('Error:', error);
    });
  
    //fetch('http://portalplatform.api.dev.duvel.com/Orders/GetItems', {
    fetch('https://posapi.duvelusa.com/Orders/GetItems', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => setOriginalItems(data))
    .catch((error) => {
      console.error('Error:', error);
    });
  }, []);

  //log customers when it gets set
  useEffect(() => {
    console.log('POS_MainPage/customers: ', customers);
  }, [customers]);


  //handleFilterChange
  function handleFilterChange(newValue, filterType) {
    //log the new value
    console.log('POS_MainPage/newValue: ', newValue);
    filterType === 'brand' ? setfilteredBrands(newValue) : setfilteredItemTypes(newValue);
  };

  useEffect(() => {
    console.log('POS_MainPage/filteredBrands: ', filteredBrands);
  }, [filteredBrands]);

  useEffect(() => {
    console.log('POS_MainPage/filteredItemTypes: ', filteredItemTypes);
  }, [filteredItemTypes]);

  const handleShoppingCartUpdate = (itemNo_, quantity, description) => {
    console.log('POS_MainPage/handleShoppingCartUpdate/updatedCart: ', itemNo_, quantity, description);
    //setShoppingCart(updatedCart);
    setShoppingCart(prevShoppingCart => {
      return {...prevShoppingCart, [itemNo_]: (prevShoppingCart[itemNo_] || 0) + quantity};
    });
    // setShoppingCart(prevShoppingCart => {
    //   return { ...prevShoppingCart, ...updatedCart };
    // });
    console.log('POS_MainPage/handleShoppingCartUpdate/shoppingCart: ', shoppingCart);
  };

  //useEffect dependent on props.username
  useEffect(() => {
    console.log('POS_MainPage/props.username: ', props.username);
  }, [props.username]);



  const handleSelect = (event) => {
    const selectedCustomerNo = event.target.value;
    const selectedCustomer = customers.find(customer => customer.customerNo_ === selectedCustomerNo);
    setSelectedCustomer(selectedCustomer);
    console.log('selectedCustomer: ', selectedCustomer);
  };

  const handleAdminClick = () => {  
    setShowAdmin(!ShowAdmin);
    setSelectedCustomer(null);
    setStartNewOrder(false);
    setShoppingCart({});
  };

  // const handleRemoveItemFromCart = (itemId) => {
  //   setShoppingCart(prevShoppingCart => prevShoppingCart.filter(item => item.id !== itemId));
  // };

  const handleRemoveItemFromCart = (itemNo_) => {
    setShoppingCart(prevShoppingCart => {
      const newShoppingCart = { ...prevShoppingCart };
      delete newShoppingCart[itemNo_];
      console.log('newShoppingCart: ', newShoppingCart);
      return newShoppingCart;
    });
  };

  // const handleRemoveItemFromCart = (itemToRemove) => {
  //   setShoppingCart(shoppingCart => shoppingCart.filter(item => item.id !== itemToRemove.id));
  // }
    
  useEffect(() => {
    console.log('the cart: ', shoppingCart);
  }, [shoppingCart]);

  const handleCancelOrder = () => {
    if (window.confirm('Are you sure you want to cancel the order and start over?')) {
      setStartNewOrder(false);
      setShoppingCart({});
    }
  };

  const handleShoppingCartEdit = (itemNo_, quantity) => {
    console.log('handleShoppingCartEdit|Editing cart item:', itemNo_, quantity);
    setShoppingCart(prevShoppingCart => {
      return {...prevShoppingCart, [itemNo_]: quantity};
    });
  };

  return (
    <OriginalItemsProvider>
      <div>


        {/* Banner */}

        <div style={{marginBottom: '32px'}}>
          <img src={pos_portal_banner} alt="POS Banner" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px'}} />
        </div>


        {/* Action Panel */}
        <div className={classes.sticky} style={{width: '80%', margin: 'auto', height: '64px', backgroundColor: 'white', marginBottom: '32px', borderRadius: '10px', border: '1px solid', display: 'flex'}}>
          {/* New div for the "Logged in as" part */}
          <div style={{backgroundColor: 'darkblue', color: 'white', fontWeight: 'bold', height: '100%', display: 'flex', alignItems: 'center', padding: '0 16px', borderRadius: '8px 0 0 8px'}}>
            <div>
              <IconButton onClick={handleGearClick}>
                <SettingsIcon style={{color: 'white', border: '2px solid white', borderRadius: '8px'}} />
              </IconButton>Logged in as: {props.username}</div>
              <Popover id={id} open={open} anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                <Button color="secondary" size="large" onClick={handleLogout}>
                  Logout
                </Button>
              </Popover>
              <div style={{ position: 'absolute',
                            bottom: '2px',
                            left: '314px',
                            fontSize: '8px',
                            textAlign: 'right'
                          }}>Version: {localStorage.getItem("appVersion")}</div>
          </div>
          {(props.username === 's.cooper@duvelusa.com' || 
            props.username === 'j.simmons@duvelusa.com' || 
            props.username === 'n.love@duvelusa.com') && (
            <div onClick={handleAdminClick}
                 style={{backgroundColor: 'red', cursor: 'pointer', color: 'white', fontWeight: 'bold', height: '100%', display: 'flex', alignItems: 'center', padding: '0 16px'}}>
              <div style={{userSelect: 'none'}}>Admin</div>
            </div>
          )}
          {!ShowAdmin && <div style={{color: 'white', fontWeight: 'bold', height: '100%', display: 'flex', alignItems: 'center', padding: '0 16px', width: '456px'}}>
            <Select onChange={handleSelect} placeholder='select customer'
                    style={{border: 'none', color: 'black', fontWeight: 'bold', fontSize: '16px', width: '100%'}}
                    MenuProps={{ disableScrollLock: true }}>
                {/* <MenuItem></MenuItem> */}
              {customers.map((customer, index) => (
                <MenuItem key={index} value={customer.customerNo_}>
                  {customer.customerNo_} - {customer.customerName}
                </MenuItem>
              ))}
            </Select>
          </div>}
          {!ShowAdmin && <div style={{display: 'flex', alignItems: 'center', height: '100%', flex: 1}}>
            <div style={{display: 'inline-block', width: '100%', textAlign: 'center', position: 'sticky', top: '0', zIndex: '5000'}}>
              {!selectedCustomer ? <><span>{'< - - '}</span><span style={{marginLeft: '2px'}}>Select a Customer</span></> : <>
              {/* <a className={classes.linkHover} style={{paddingRight: '16px', paddingLeft: '16px', cursor: 'pointer'}}>0 current open orders</a> */}
              {/* <a className={classes.linkHover} style={{paddingRight: '16px', paddingLeft: '16px', cursor: 'pointer'}}>View Order History</a> */}
              
              {/* {startNewOrder ? null : 
                <button disabled={startNewOrder} className={classes.startNewOrderButton} onClick={() => setStartNewOrder(true)}
                        style={{paddingRight: '16px', paddingLeft: '16px', cursor: 'pointer', color: 'green', fontWeight: '900', marginLeft: '32px',
                        borderRadius: '24px', border: '1px solid green', backgroundColor: 'white', height: '32px', width: '164px'}}
                >Start New Order</button>} */}
              </>}
            </div>
          </div>}
        </div>


        {/* Shop */}  
        
        {!ShowAdmin && 
        <Grid container spacing={1}>
          
          {/* Left Column */}
          
          <Grid item xs={3} className={classes.gridItem}>
            <Box className={classes.customComponent}>
              <FilterPanel newOrder={startNewOrder} theme={props.theme} handleFilterChange={handleFilterChange} />
            </Box>
          </Grid>

          {/* Middle Column */}

          <Grid item xs={7} className={classes.gridItem}>
            {/* <Item /> */}
            {!selectedCustomer ? null : <>
              {startNewOrder ? null : 
                <button disabled={startNewOrder} className={classes.startNewOrderButton} onClick={() => setStartNewOrder(true)}
                        style={{paddingRight: '16px', paddingLeft: '16px', cursor: 'pointer', color: 'green', fontWeight: '900', marginLeft: '32px',
                        marginTop: '56px', borderRadius: '24px', border: '1px solid green', backgroundColor: 'white', fontSize: '24px',
                        height: '128px', width: '164px', margin: 'auto', width: '50%'}}
                >Start New Order</button>}
            
            </>}
            {!startNewOrder ?
              <div style={{marginTop: '128px'}}>
                <Skeleton variant="rect" width={'80%'} height={64} style={{margin: 'auto', borderRadius: '10px', marginBottom: '32px'}} />
                <Skeleton variant="rect" width={'80%'} height={64} style={{margin: 'auto', borderRadius: '10px', marginBottom: '32px'}} />
                <Skeleton variant="rect" width={'80%'} height={64} style={{margin: 'auto', borderRadius: '10px', marginBottom: '32px'}} />
                <Skeleton variant="rect" width={'80%'} height={64} style={{margin: 'auto', borderRadius: '10px', marginBottom: '32px'}} />
              </div> : (originalItems.length > 35 ? (
                          <ItemsList
                            originalItems={originalItems}
                            brandFilter={filteredBrands}
                            itemTypeFilter={filteredItemTypes}
                            onCartUpdate={handleShoppingCartUpdate}
                            shoppingCart={shoppingCart}
                          />
                        ) : (
                            <div style={{ marginTop: '128px' }}>
                              <p>Loading items, please wait...</p>
                            </div>
                          )
                      )}
          </Grid>

          {/* Right Column */}

          <Grid item xs={2} className={classes.gridItem}>
            <Box className={classes.customComponent}>
              <ShoppingCart startNewOrder={startNewOrder} originalItems={originalItems} cartItems={shoppingCart} selectedCustomer={selectedCustomer} 
                            onRemove={handleRemoveItemFromCart} onEdit={handleShoppingCartEdit}
                            onCancelOrder={handleCancelOrder} username={props.username} />
            </Box>
          </Grid> 
        </Grid>}

        {/* Admin */}
        {ShowAdmin && <div style={{display: 'flex', alignItems: 'center', height: '100%', flex: 1}}>
            <Admin originalItems={originalItems} />
        </div>}
        
      </div>
    </OriginalItemsProvider>
  );
}

export default POS_MainPage;
